.holiday{
    text-shadow: 0px 0px 5px rgba(255, 255, 255, 1), 
               0px 0px 10px rgba(255, 255, 255, 1), 
			   0px 0px 15px rgba(255, 255, 255, 1),
			   0px 0px 20px rgba(73, 255, 24, 1),
			   0px 0px 30px rgba(73, 255, 24, 1),
			   0px 0px 40px rgba(73, 255, 24, 1),
			   0px 0px 55px rgba(73, 255, 24, 1),
			   0px 0px 75px rgba(73, 255, 24, 1)

}
.snow{
    background-color: darkgray;
}